import { render, staticRenderFns } from "./VueImage.vue?vue&type=template&id=275f8f10&scoped=true&lang=pug&"
import script from "./VueImage.vue?vue&type=script&lang=js&"
export * from "./VueImage.vue?vue&type=script&lang=js&"
import style0 from "./VueImage.vue?vue&type=style&index=0&id=275f8f10&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "275f8f10",
  null
  
)

export default component.exports